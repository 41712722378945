import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/tZHp53Gixu8">
    <SEO title="A Religious Relationship - Romans: Principles" />
  </Layout>
)

export default SermonPost
